a
  text-decoration: none
  &:active
    opacity: 0.7
    -webkit-tap-highlight-color: rgba(black, 0)
.contentinfo a,
a.normal-text
  color: $body-color
a.disabled
  pointer-events: none
  cursor: pointer


// Pagenav (on top)
// ---------------------------------------------------------------------

#page_headernav
  @extend .navbar
  @extend .fixed-top
  @extend .navbar-expand-lg
  @extend .navbar-light
  @extend .bg-light
  @extend .justify-content-between
  @extend .box_shadow_default
  // box-shadow: rgba($gray-dark, 0.2) 0px 1px 3px 0px, rgba($gray-dark, 0.15) 0px 6px 10px 0px
  z-index: 1031
  height: $page_headernav-height
  padding-top: 0
  padding-bottom: 0
  background: white!important
  border-bottom: 1px solid $gray-lighter

// Action navigation inside #page_headernav
#action_nav
  position: absolute
  left: $content-padding
  top: 0
  height: $page_headernav_height
  +media-breakpoint-up(sm)
    right: $content-padding
    left: auto
  ul
    @extend .nav
    // @extend .navbar-right
    font-weight: bold
    letter-spacing: -0.05em
    margin-left: -15px
    // No collapse!
    +media-breakpoint-down(md)
      margin-top: 0
      margin-bottom: 0
      li
        a
          padding-top: $page_headernav_height * 0.5 - 10px
          padding-bottom: $page_headernav_height * 0.5 - 10px
    li
      height: $page_headernav_height
      border-right: 1px solid $gray-lighter
      a
        color: $gray
        padding-top: 18px
        &:focus,
        &:hover
          color: $body-color
        i
          @extend .fa-lg
      &:last-child
        border: none
      &.active
        a
          color: $body-color
          i
            color: $brand-primary
      +media-breakpoint-down(md)
        span
          display: none


// Sidebar and navigation
// ---------------------------------------------------------------------

// #page_sidenav toggler inside #page_headernav
#toggle_sidenav
  position: absolute
  top: 19px
  right: $content-padding + 1
  +media-breakpoint-up(sm)
    right: auto
    left: $grid-gutter-width
  +media-breakpoint-up(lg)
    display: none
  .hamburger_button
    cursor: pointer
    margin: 0
    padding: 0
    background: none
    border: none
    -webkit-tap-highlight-color: rgba(white, 0)!important
    -webkit-tap-highlight-color: transparent!important // Nexus5/Chrome and Kindle Fire HD 7
    &:focus
      color: rgba(white, 0)
      outline: 0
      border: none
    // Bars
    .icon-bar
      display: block
      height: 2px
      background-color: $body-color
      border-radius: 1px
    .icon-bar + .icon-bar
      margin-top: 5px
    &.collapsed
      .top-bar
        transform: rotate(0)
      .middle-bar
        opacity: 1
      .bottom-bar
        transform: rotate(0)
    .icon-bar
      width: 28px
      transition: .25s ease-in-out
    .top-bar
      transform: rotate(45deg)
      transform-origin: 15% 10%
    .middle-bar
      opacity: 0
    .bottom-bar
      transform: rotate(-45deg)
      transform-origin: 10% 85%

#user-info-main
  position: sticky
  overflow: clip
  color: $body-color
  text-align: center
  padding: 20px $grid-gutter-width * 0.5
  background: rgba(white, 0.3)
  border-bottom: 1px solid lighten($gray-light, 15)
  // $logo_custom_height: 150px
  img.main_logo
    position: relative
    z-index: 2
    margin-bottom: 15px
  img.bg_logo
    // Show only when Modernizr detects support for cssfilters
    html.supports.cssfilters &
      display: block!important
      position: absolute
      top: 0
      right: 0
      bottom: 0
      left: 0
      z-index: 0
      width: 100%
      height: 100%
      max-width: none
      max-height: none
      opacity: 0.2
      filter: brightness(1.2) contrast(1.1) blur(40px)
  a
    color: $body-color
  .username
    font-size: 1.12rem
    font-weight: 800
    margin: 0
    a
      display: block
      position: relative
      word-wrap: break-all
      .fa
        $size: 32px
        color: white
        font-size: 20px
        position: absolute
        top: 50%
        left: 50%
        visibility: hidden
        line-height: $size
        text-align: center
        width: $size
        height: $size
        margin-top: - $size * 0.5
        margin-left: - $size * 0.5
        background: $brand-primary
        border-radius: $size * 0.5
        box-shadow: 0, 2px, 5px, rgba(black, 0.5)
      &:focus,
      &:hover
        color: $gray
        text-decoration: none
        transition: 0.3s
        .fa
          visibility: visible
          color: white
          transition: 0.3s
  .user_organization_role
    position: relative
    z-index: 2
    color: $gray
    font-size: 1rem
    font-weight: bold
    margin: 5px 0 0 0
  .user_role
    font-size: 0.875rem
    margin-bottom: 0.4rem
    border: 1px solid rgba(black, 0.15)

#page_sidenav
  font-size: 1.125rem
  +media-breakpoint-up(md)
    font-size: 1rem
  right: auto
  left: 0
  width: 80%
  padding-top: $page_headernav_height
  border: none
  background: linear-gradient(90deg, $navmenu-default-bg 0%, $navmenu-default-bg 93%, darken($navmenu-default-bg, 2) 100%)
  border-right: 1px solid $gray-lighter
  -webkit-overflow-scrolling: touch // smooth scrolling
  +media-breakpoint-down(md)
    z-index: 10
    opacity: 0
    &.in
      opacity: 1
  +media-breakpoint-up(sm)
    width: $page_sidenav_width
  i.fa
    @extend .fa-lg
    width: 30px
  i.more
    float: right
    width: auto
  .nav
    letter-spacing: -0.05em
  hr
    margin: 5px 25px
    border-color: $gray-lighter
  li
    &.divider
      height: 1px
      margin: 12px 0
      background-color: lighten($gray-light, 15)
    a
      font-weight: bold
      margin: 2px 12px
      padding-top: 12px
      padding-bottom: 12px
      border-radius: $border-radius-sm
      i
        color: $gray
      &:focus
        outline: none
        background: none
      &:hover
        i
          color: $navmenu-default-link-hover-color
    &.active
      a
        background-color: white
        +media-breakpoint-down(xs)
          margin-top: 10px
          margin-bottom: 10px
        i
          color: $brand-primary
    // first level link, containing a dropdown
    &.dropdown
      a.dropdown-toggle
        &:after
          color: $body-color
      &.open
        a.dropdown-toggle
          cursor: default
          background: none!important
          i
            color: $brand-primary
  .dropdown-menu
    padding-bottom: 0
    background: transparent
    box-shadow: none
    li
      a
        padding-top: 10px
        padding-bottom: 10px
        padding-left: 49px
        font-size: 1.125rem
        +media-breakpoint-up(md)
          font-size: 1rem
        &:focus,
        &:hover
          color: $navmenu-default-link-hover-color
// Need some spacing before the sidenav navigation
.navmenu-nav
  padding: 15px 0

// Footer navigation
// ---------------------------------------------------------------------

#footernav
  padding: 50px $grid-gutter-width * 0.5
  padding-bottom: 0
  +media-breakpoint-up(sm)
    padding-right: $grid-gutter-width
    padding-left: $grid-gutter-width
  h6
    opacity: 0.8
    text-transform: uppercase
    margin-top: 10px
  ul
    @extend .list-unstyled
    li
      padding-top: 7px
      padding-bottom: 7px
      +media-breakpoint-up(md)
        padding-top: 5px
        padding-bottom: 5px
  i
    width: 16px


// Organization/Account selector
// ---------------------------------------------------------------------

.organization_selector
  @extend .list-group
  li
    @extend .list-group-item
    padding: 0
    &:hover,
    &:active
      background-color: lighten(desaturate($info, 25), 55)
    a
      color: $body-color
      line-height: 1.1
      display: flex
      justify-content: space-between
      padding: 15px 20px
      background: none
      &:hover,
      &:active
        text-decoration: none
      .nameing
        font-weight: bold
        // line-height: 15px
      .contractor_name
        display: block
        padding-bottom: 6px
      .logo
        @extend .pull-right
        display: flex
        text-align: right
        height: 40px
        width: 120px
        margin-left: 10px
        align-self: center
        align-items: center
        justify-content: flex-end
        img
          max-width: 80px
          max-height: 25px
          height: auto
    &.active
      @extend .list-group-item-info
      border-color: $brand-secondary!important
      a
        cursor: default


// Misc
// ---------------------------------------------------------------------

.nav-tabs-scrollable
  overflow-x: auto
  overflow-y: hidden
  -webkit-overflow-scrolling: touch // smooth scrolling
  ul
    white-space: nowrap
    display: inline-block // needed so border is visible despite overflow
    min-width: 100% // needed so border is visible despite overflow
    > li
      float: none
      display: inline-block

#tertiarynav
  @extend .nav-tabs-scrollable
  margin: (-$content-padding) (-$content-padding) 35px (-$content-padding)
  padding: (2 * $content-padding) 0 0 0
  +media-breakpoint-up(sm)
    margin-right: 0
    margin-left: 0
    box-shadow: none
  ul
    @extend .nav
    @extend .nav-tabs
    letter-spacing: -0.04em
    padding-right: 15px
    padding-left: 15px
    border-color: $gray-light
    +media-breakpoint-up(sm)
      padding-right: 5px
      padding-left: 5px
    > li
      @extend .nav-item
      float: none
      display: inline-block
      &.active > a
        background: $body-bg!important
        border-top-color: $gray-light
        border-right-color: $gray-light
        border-left-color: $gray-light
      > a
        @extend .nav-link
        padding: 0.53rem 1.5rem
        &:focus,
        &:hover
          border-top-color: $gray-light!important
          border-right-color: $gray-light!important
          border-bottom-color: $body-bg!important
          border-left-color: $gray-light!important

#quaternarynav
  .action_nav_link
    &.active
      color: $gray
      background-color: rgba(255, 255, 255, 0.7)


// Inspection, Notice, TimeTracking … new
nav.index_actions
  @extend .clearfix
  margin: 0 0 30px 0
  +media-breakpoint-down(sm)
    display: flex
    flex-direction: column
    align-items: flex-start
  .crud_action_new,
  .btn-group-exports
    clear: both
    float: left

  .btn-group-exports
    margin-top: 10px
  +media-breakpoint-up(md)
    .btn-group-exports
      clear: none
      float: right
      margin-top: 0
    .reports_defects_btn,
    .crud_action_new
      margin-bottom: 0

// "Go to 'resource' index"
.index_resource
  font-weight: bold
  margin-top: 40px
  a
    @extend .btn
    @extend .btn-outline-dark

// Export/Download PDF/XLS …
nav.export
  @extend .clearfix
  padding-bottom: 1.5rem

// Set report output
nav.output
  margin-bottom: 40px
  padding-bottom: 20px
  border-bottom: 1px solid $gray-lighter
  .datepickers
    #select_day
      letter-spacing: -0.06em
  .btn-output
    @extend .btn-info
    float: right
    margin-top: .5rem
    &.btn-output-inline
      float: none
      margin-top: 1.93rem
    +media-breakpoint-up(sm)
      float: none
      margin-top: 1.75rem

// Dropdown links not clickable on mobile fix
.navmenu-nav.dropdown-menu
  position: relative
.dropdown-backdrop
  position: relative

.qrlink-select-data-acquisition
  li
    @extend .list-group-item

// Indexview Toolbar
.pager-info
  +media-breakpoint-down(xs)
    .model-name,
    .count
      display: inline-block
      max-width: 120px
      overflow: hidden
      white-space: nowrap
      text-overflow: ellipsis
    .count
      max-width: 100px


// Tabs
// ---------------------------------------------------------------------

.nav-tabs
  font-weight: bold
  > li
    a.nav-link
      color: $gray
      border-top-left-radius: $border-radius
      border-top-right-radius: $border-radius
      &:focus,
      &:hover
        background: rgba(white, 0.7)
    &.active > a
      color: $body-color
      background: white
      border-bottom-color: $body-bg
      border-bottom-width: 2px
.tab-content
  @extend .box_shadow_default
  color: $body-color
  background-color: white
  border-right: 1px solid #ddd
  border-bottom: 1px solid #ddd
  border-left: 1px solid #ddd
  .table-responsive
    box-shadow: none
