// Bootstrap Vars
// ---------------------------------------------------------------------

// Colors
$brand-primary: #27b6af
$brand-secondary: #1f7bb6
//
$gray-base: #0a0a0f
$gray-darker: lighten($gray-base, 10)
$gray-dark: lighten(desaturate($gray-base, 40), 20)
$gray: lighten(desaturate($gray-base, 1), 20)
$gray-light-2: lighten(desaturate($gray-base, 10), 35)
$gray-light: lighten(desaturate($gray-base, 10), 58)
$gray-lighter: lighten(desaturate($gray-base, 8), 70)
$gray-lightest: lighten(desaturate($gray-base, 2), 84)
//
$theme-colors: ("primary": $brand-primary, "warning": #f0ad4e, "info": $brand-secondary, "success": #5cb85c, "secondary": $gray-light, "dark": $gray)
$brand-danger: #dc3545
$brand-warning: #f0ad4e
//
$white-light: rgba(white, 0.7)
$body-color: $gray-darker
// $body-bg: #f3f2f1
$body-bg: lighten(desaturate($gray-base, 2), 90)
// $body-bg: lighten($gray-base, 92) // own
$link-color: $brand-primary
$link-hover-color: darken($brand-primary, 10)

// Components
// $padding-base-vertical: 4px
$border-radius: 0.375rem
$border-radius-lg: 0.625rem
$border-radius-sm: 0.25rem
// Grid system
// $screen-sm: 780px
$grid-breakpoints: (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px)
$grid-gutter-width: 30px
@media (min-width: 0) and (max-width: map-get($grid-breakpoints, sm))
  .row
    margin-right: -5px!important
    margin-left: -5px!important
    > .col,
    > [class*="col-"]
      padding-right: 5px
      padding-left: 5px
$content-padding: $grid-gutter-width * 0.5 // own
$container-tablet: (620px + $grid-gutter-width)
// Navbar
$page_headernav_height: 55px

// Navmenu (sidenav)
$page_sidenav_width: 300px
$navmenu-width: $page_sidenav_width
$navmenu-default-color: white
$navmenu-default-bg: $gray-lightest
$navmenu-default-border: transparent
$navmenu-default-link-color: $body-color
$navmenu-default-link-hover-color: $body-color
$navmenu-default-link-hover-bg: lighten($navmenu-default-bg, 15)
$navmenu-default-link-active-color: $body-color
$navmenu-default-link-active-bg: white
// Tabs
$nav-tabs-link-active-color: $body-color
$nav-tabs-link-active-bg: white
// $nav-tabs-link-active-border-color
$nav-tabs-active-link-hover-bg: white
// Pagination
$pagination-color: $body-color
$pagination-bg: transparent
$pagination-border-color: $gray-light
$pagination-hover-color: $gray
$pagination-hover-bg: white
$pagination-hover-border-color: $gray
$pagination-active-color: $brand-primary
$pagination-active-bg: $white-light
$pagination-active-border-color: $gray
$pagination-disabled-color: $gray
$pagination-disabled-bg: transparent
$pagination-disabled-border-color: $gray-light

// Tables
$table-border-color: lighten($gray-light, 25)
$table-hover-bg: $gray-lightest
$table-cell-padding: 0.5rem 0.9rem
// $table-cell-padding-sm: 0.3rem 0.9rem

// Fonts
$font-size-base: 1rem
$line-height-base: 1.35
$font-family-sans-serif: "CGE Sans", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"
$font-family-serif: Georgia, serif
$font-family-monospace: "CGE Mono", monospace

// Forms
$input-border-color: $gray-light
$input-border-focus: $brand-primary
$input-disabled-bg: $gray-lightest
$input-group-addon-bg: lighten($gray-lighter, 12)
$input-focus-border-color: $input-border-color
$input-padding-y: 0.5625rem
$input-padding-x: 0.6875rem

// Buttons
$btn-padding-y: 0.5625rem
$btn-padding-x: 0.6875rem
// $input-group-addon-border-color: transparent

// Type
$hr-border-color: $gray-light

// Wells
$well-bg: white

// Cards
// $card-cap-bg: white

// Dropdowns
$dropdown-link-hover-bg: $gray-lightest
// $dropdown-box-shadow: 0 10px 30px red
$dropdown-item-padding-y: 0.625rem

// Custom file fields
$custom-file-padding-y: 0.625rem
$custom-file-height: 2.625rem


// Dark mode for development
// ---------------------------------------------------------------------

// $body-color: white
// $body-bg: $gray-dark
// $card-bg: $gray
// $table-bg: $gray
// .data-item-details
//   color: black!important
// .header #page_headernav
//   background-color: $gray!important
//   a
//     color: $gray-lightest

// .select2
//   .select2-selection.select2-selection--single
//     background: $gray!important
//   .select2-selection__rendered
//     color: white!important
// .select2-container
//   .select2-dropdown
//     background: $gray!important
// $input-color: white
// $input-bg: $gray
// $input-disabled-bg: darken($gray, 5)

// body #page_sidenav li.active a
//   color: white!important
