// Layout
// ---------------------------------------------------------------------

html
  display: flex // let IE 11 play along

body
  display: flex
  flex-direction: column
  width: 100%
  min-height: 100vh
  padding-top: $page_headernav_height
  +selection($body-color, rgba($brand-primary, 0.4))

#logo
  display: block
  float: none
  text-align: center
  width: $page_sidenav_width - $grid-gutter-width
  margin-right: auto
  margin-left: auto
  padding-left: 0
  .signet
    fill: $gray-lighter
  &.development
    .signet
      fill: $brand-primary
  +media-breakpoint-up(lg)
    display: initial
    float: left
    margin-left: 0

.main
  flex: 1 0 auto // let IE11 play along
  +media-breakpoint-down(md)
    z-index: 11

#page_content
  position: relative
  min-height: 100vh
  padding: $grid-gutter-width * 0.5
  background: $body-bg
  +media-breakpoint-up(sm)
    padding-right: $grid-gutter-width
    padding-left: $grid-gutter-width
  #inner
    max-width: 1920px - $page_sidenav_width
    margin-right: auto
    margin-left: auto
    padding-bottom: 60px

.two-columns
  .column-actions
    position: relative
    flex: 1
    li.dropdown-header
      @extend .pt-3
      @extend .list-group-item
      cursor: default
      font-size: 1rem
      text-wrap: wrap
      border-right: 1px solid $gray-light
      border-left: 1px solid $gray-light
    li.divider,
    li.dropdown-divider
      margin: 0
      border-width: 3px
    .dropdown-item
      white-space: normal
  .column-main
    > div
      max-width: 900px
      margin: 0 auto

#page_sidenav + .main
  @include media-breakpoint-up(lg)
    margin-left: $page_sidenav_width
    // Sidenav tablet scroll flicker fix
    &:after
      content: " "
      position: fixed
      left: 0
      bottom: 0
      z-index: 1
      height: 200px
      width: $page_sidenav_width
      background: $navmenu-default-bg

#page_footer
  color: $gray-dark
  font-size: 0.9375rem
  letter-spacing: -0.035rem
  width: 100%
  background: $gray-lighter
  border-top: 1px solid lighten($gray-light, 10)
  padding-right: 5px
  padding-left: 5px
  // background-image: linear-gradient(darken($body-bg, 10), darken($body-bg, 5) 15%, darken($body-bg, 2) 50%, $body-bg)
  h6
    color: $gray
  a
    color: $gray-dark
    text-decoration: none
    fill: $gray
    &:focus,
    &:hover
      color: $gray
      text-decoration: underline
      fill: $gray-dark
  #footerinfo
    margin-top: 50px
    padding: 30px 0
    border-top: 1px solid rgba(black, 0.1)
    .logo
      text-align: center
      &:focus,
      &:hover
        opacity: 1
      .logo-img
        max-width: 155px
      img
        height: auto


// Misc
// ---------------------------------------------------------------------

a.download
  padding: 12px 15px


// Dark UI
// ---------------------------------------------------------------------

// @media (prefers-color-scheme: dark)
//   body
//     color: $body-bg
//     background-color: $body-color
//   body #page_headernav
//     background-color: $gray-dark!important
//   #page_content
//     background: $body-color
//   .table,
//   .table-responsive
//     color: $body-bg
//     background: $body-color!important
//     th a
//       color: white!important
//   #tertiarynav
//     background: transparent!important
//   input,
//   textarea,
//   .select2-selection,
//   .checkboxes-container,
//   .radio-buttons-container
//     color: $body-bg!important
//     background-color: rgba(white, 0.1)!important
//   .select2-selection__rendered
//     color: white!important
//   .card,
//   .list-group-item
//     background: rgba(white, 0.1)!important
//   .btn
//     color: white
//   .data-item
//     .data-item-details
//       color: $body-color
//       +dog-ear($foreground-color: $gray-lightest, $background-color: $body-color)
//   .card
//     .grid-view &
//       .thumbnail
//         +dog-ear($foreground-color: $gray-lightest, $background-color: $body-color)
